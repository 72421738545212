import React, { useEffect, useState } from "react";

import { Row, Col, Typography } from "antd";
import "./css/home.css";
import Carouse from "./components/Carousel";
import img1 from "./assets/coc02.jpg";
import truck from "./assets/truck-1058.svg";
import quality from "./assets/OIP.jpeg";
import Slider from "react-slick";
import secure from "./assets/secure.jpeg";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { _fetch } from "./_fetch.js";
import ImageCard from "./components/Card.js";
let contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="customprev" onClick={onClick}>
      <FaChevronLeft size={25} color={"black"} />
    </div>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="customnext" onClick={onClick}>
      <FaChevronRight size={25} color={"black"} />
    </div>
  );
}

export default function Home() {
  const [product, setProduct] = useState([]);
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  async function getData() {
    let res = await _fetch(`${apiurl}/api/v1/product`);
    res = await res.json();
    setProduct(res.response);
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Row align="center">
        <Col span={20} style={{ maxHeight: "280px" }} className="column">
          <Carouse></Carouse>
        </Col>
      </Row>
      <Row align="center" style={{ margin: "20px 10px", gap: "10px" }}>
        <Col align="center" className="Trend" span={24}>
          Wooden Spoons
        </Col>
        <Col
          align="center"
          className="Trend"
          span={24}
          style={{
            color: "#808080",
            fontFamily: "Dosis",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Want to stay in trend? Take a look at these super trendy products.
        </Col>
        <div
          className="slidercust"
          style={{ height: "fit-content", width: "100%" }}
        >
          <div
            // className="mt-4 md:mt-0 md:pr-4 lg:pr-8"
            style={{
              overflow: "hidden",
              height: "fit-content",
              width: "100%",
              position: "relative",
            }}
          >
            <Slider
              dots={false}
              speed={500}
              autoplay={true}
              autoPlaySpeed={3000}
              slidesToScroll={1}
              // infinite={true}
              responsive={[
                {
                  breakpoint: 3750,
                  settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1750,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 825,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {product
                ?.filter((data) => data?.sub_category_id?.name === "Spoon")
                ?.map((item, index) => {
                  return (
                    <ImageCard
                      key={index}
                      customStyle={{
                        border: "2px solid #808080",
                        borderRadius: "5px",
                        // maxWidth: "250px",
                      }}
                      name={item?.name}
                      imageLink={`${process.env.REACT_APP_AWS_S3_URL}/${item?.logo?.path}`}
                      slug={item?.slug}
                      price={item?.price}
                      discountedprice={
                        item?.discount ? item?.discount : item?.price
                      }
                      rating={item?.rating}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      </Row>

      <Row align="center" style={{ margin: "20px 10px", gap: "10px" }}>
        <Col align="center" className="Trend" span={24}>
          Coconut Wooden Spoons & Caserols
        </Col>
        <Col
          align="center"
          className="Trend"
          span={24}
          style={{
            color: "#808080",
            fontFamily: "Dosis",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Want to stay in trend? Take a look at these super trendy products.
        </Col>
        <div
          className="slidercust"
          style={{ height: "fit-content", width: "100%" }}
        >
          <div
            // className="mt-4 md:mt-0 md:pr-4 lg:pr-8"
            style={{
              overflow: "hidden",
              height: "fit-content",
              width: "100%",
              position: "relative",
            }}
          >
            <Slider
              dots={false}
              speed={500}
              autoplay={true}
              autoPlaySpeed={3000}
              slidesToScroll={1}
              infinite={true}
              responsive={[
                {
                  breakpoint: 3750,
                  settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1750,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 825,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {product
                ?.filter(
                  (data) =>
                    data?.sub_category_id?.name === "Cocunut wood Spoons" ||
                    data?.sub_category_id?.name === "Caserol"
                )
                ?.map((item, index) => {
                  return (
                    <ImageCard
                      key={index}
                      customStyle={{
                        border: "2px solid #808080",
                        borderRadius: "5px",
                        // maxWidth: "250px",
                      }}
                      name={item?.name}
                      imageLink={`${process.env.REACT_APP_AWS_S3_URL}/${item?.logo?.path}`}
                      slug={item?.slug}
                      price={item?.price}
                      discountedprice={
                        item?.discount ? item?.discount : item?.price
                      }
                      rating={item?.rating}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      </Row>

      {/* <Row align="center" style={{ margin: "20px 10px", gap: "10px" }}>
        <Col align="center" className="Trend" span={24}>
          Dry Fruits and Spices
        </Col>
        <Col
          align="center"
          className="Trend"
          span={24}
          style={{
            color: "#808080",
            fontFamily: "Dosis",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Elevate Your Everyday: Discover the Richness of Nature with Our
          Premium Dry Fruits and Spices!
        </Col>
        <div
          className="slidercust"
          style={{ height: "fit-content", width: "100%" }}
        >
          <div
            // className="mt-4 md:mt-0 md:pr-4 lg:pr-8"
            style={{
              overflow: "hidden",
              height: "fit-content",
              width: "100%",
              position: "relative",
            }}
          >
            <Slider
              dots={false}
              speed={500}
              autoplay={true}
              autoPlaySpeed={3000}
              slidesToScroll={1}
              infinite={true}
              responsive={[
                {
                  breakpoint: 3750,
                  settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1750,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 825,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {product
                ?.filter(
                  (data) =>
                    data?.sub_category_id?.name === "Dry Fruit" ||
                    data?.sub_category_id?.name === "Spices"
                )
                ?.map((item, index) => {
                  return (
                    <ImageCard
                      key={index}
                      customStyle={{
                        border: "2px solid #808080",
                        borderRadius: "5px",
                        // maxWidth: "250px",
                      }}
                      name={item?.name}
                      imageLink={`${process.env.REACT_APP_AWS_S3_URL}/${item?.logo?.path}`}
                      slug={item?.slug}
                      price={item?.price}
                      discountedprice={
                        item?.discount ? item?.discount : item?.price
                      }
                      rating={item?.rating}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      </Row> */}
    </div>
  );
}
