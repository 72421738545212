import React, { useState, useEffect } from "react";

import { Row, Col, Input } from "antd";
import "./css/shop.css";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { _fetch } from "./_fetch";
import ImageCard from "./components/Card.js";
import { Checkbox } from "antd";
const { Search } = Input;
export default function Shop() {
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [filter, setFilter] = useState({
    category_id: "",
    subcategory_id: "",
  });
  const onChange = async (e, checkedValues, isCategory) => {
    let obj = {};
    if (isCategory && e.target.checked) {
      let str = "";
      str += filter?.category_id;
      str += `${str.length > 0 ? "," : ""}` + checkedValues;
      setFilter({ ...filter, category_id: str });
      obj = { ...filter, category_id: str };
    } else if (isCategory && !e.target.checked) {
      let arr = filter?.category_id.split(",");
      let indexToRemove = arr.indexOf(checkedValues);
      if (indexToRemove !== -1) {
        arr.splice(indexToRemove, 1);
      }
      let newstr = arr.join(",");
      setFilter({ ...filter, category_id: newstr });
      obj = { ...filter, category_id: newstr };
    } else if (!isCategory && e.target.checked) {
      let str = "";
      str += filter?.subcategory_id;
      str += `${str.length > 0 ? "," : ""}` + checkedValues;
      setFilter({ ...filter, subcategory_id: str });
      obj = { ...filter, subcategory_id: str };
    } else if (!isCategory && !e.target.checked) {
      let arr = filter?.subcategory_id.split(",");
      let indexToRemove = arr.indexOf(checkedValues);
      if (indexToRemove !== -1) {
        arr.splice(indexToRemove, 1);
      }
      let newstr = arr.join(",");
      setFilter({ ...filter, subcategory_id: newstr });
      obj = { ...filter, subcategory_id: newstr };
    }
    let res = await _fetch(`${apiurl}/api/v1/product/filter`, {
      method: "POST",
      body: obj,
    });
    res = await res.json();
    if (res.success) {
      setProduct(res.response);
    } else {
      setProduct([]);
    }
  };
  const onSearch = async (value, _e, info) => {
    let res = await _fetch(`${apiurl}/api/v1/product/filter`, {
      method: "POST",
      body: {
        tags: [value],
      },
    });
    res = await res.json();
    if (res.success) {
      setProduct(res.response);
    }
  };
  async function getData() {
    let res = await _fetch(`${apiurl}/api/v1/subcategory`);
    res = await res.json();
    setSubCategory(res.response);
    res = await _fetch(`${apiurl}/api/v1/category`);
    res = await res.json();
    setCategory(res.response);
    res = await _fetch(`${apiurl}/api/v1/product`);
    res = await res.json();
    setProduct(res.response);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Search
        placeholder="input search text"
        allowClear
        onSearch={onSearch}
        style={{ width: "30%", top: "50px" }}
      />
      <div className="filterproductList">
        <div
          style={{
            width: "30%",
            marginTop: "50px",
            maxWidth: "250px",
            position: "-webkit-sticky",
            position: "sticky",
          }}
        >
          <Row style={{ marginTop: "10px" }}>
            <Col
              style={{
                fontWeight: 700,
                textTransform: "uppercase",
                lineHeight: "24px",
              }}
            >
              Filter
            </Col>
          </Row>

          {category?.map((categ) => {
            return (
              <>
                <Row align="flex-start" style={{ marginTop: "10px" }}>
                  <Col
                    style={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                      lineHeight: "14px",
                      paddingBottom: "8px",
                    }}
                  >
                    <Checkbox onChange={(e) => onChange(e, categ._id, true)}>
                      {categ.name}
                    </Checkbox>
                  </Col>
                </Row>
                {subcategory?.map((subcat) => {
                  if (subcat?.category_id._id === categ._id) {
                    return (
                      <Row
                        style={{
                          fontWeight: 600,
                          textTransform: "uppercase",
                          lineHeight: "5px",
                        }}
                      >
                        <Checkbox
                          onChange={(e) => onChange(e, subcat._id, false)}
                        >
                          {subcat.name}
                        </Checkbox>
                      </Row>
                    );
                  } else {
                    return "";
                  }
                })}
              </>
            );
          })}
        </div>
        <Row
          align="center"
          style={{
            // minWidth: "70%",
            paddingRight: "30px",
            marginTop: "50px",
            height: "fit-content",
            overflow: "auto",
            gap: "5px",
            width: "100%",
            maxWidth: "fit-content",
          }}
        >
          {product?.map((item, index) => {
            return (
              <Col
                xs={20}
                sm={8}
                md={6}
                lg={5}
                xl={4}
                style={{ height: "fit-content", width: "100%" }}
              >
                <ImageCard
                  key={index}
                  customStyle={{
                    border: "2px solid #808080",
                    borderRadius: "5px",
                  }}
                  name={item?.name}
                  imageLink={`${process.env.REACT_APP_AWS_S3_URL}/${item?.logo?.path}`}
                  slug={item?.slug}
                  price={item?.price}
                  discountedprice={
                    item?.discount ? item?.discount : item?.price
                  }
                  rating={item?.rating}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
