import React, { useState } from "react";

import { Button, Checkbox, Flex, Form, Input, message } from "antd";
import { _fetch } from "./_fetch";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase/setup";
const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};
const ForgotPassword = () => {
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [cookies, setCookie] = useCookies(["gsa-user"]);
  const navigate = useNavigate();
  const [sendOtp, setSendOtp] = useState(true);
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState();
  const [userverifybyotp, setUserVerifyByOtp] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const sendOtpToCustomer = async (value) => {
    try {
      setLoading(true);
      let res = await _fetch(`${apiurl}/api/v1/user/filter`, {
        method: "POST",
        body: { loginByOtp: true, phone: phone },
      });
      res = await res.json();
      if (!res.success) {
        return message.error("Please sign up to continue");
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      if (!recaptcha) {
        return message.error("Too Many Attempts");
      }
      setLoading(false);
      const confirmation = await signInWithPhoneNumber(
        auth,
        "+" + phone,
        recaptcha
      );
      setUserVerifyByOtp(confirmation);
      setSendOtp(true);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };
  const verifyOtp = async (value) => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/forgotpassword`, {
        method: "POST",
        body: { email, new_password: password, otp: value.otp },
      });
      res = await res.json();

      if (res.success) {
        navigate("/login");
        message.success("Your password has been reset.Please login again!");
      } else {
        message.error("Something Went Wrong");
      }
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };
  const sendOtpToUser = async (values) => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/getotp`, {
        method: "POST",
        body: { email: values.email },
      });
      res = await res.json();

      if (res.success) {
        setSendOtp(false);
      } else {
        message.error("Something Went Wrong");
      }
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        minHeight: "79vh",
      }}
    >
      {sendOtp ? (
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          style={{ width: "50%" }}
          onFinish={sendOtpToUser}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            style={{ width: "100%" }}
          >
            <Button type="primary" htmlType="submit">
              Send Otp
            </Button>
          </Form.Item>

          {/* <a
            type="primary"
            onClick={() => setLoginByOtp(true)}
            style={{ marginLeft: "10px" }}
          >
            Send OTP
          </a> */}
        </Form>
      ) : (
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          style={{ width: "50%" }}
          onFinish={verifyOtp}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              disabled={email ? true : false}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Otp"
            name="otp"
            rules={[
              {
                required: true,
                message: "Please input your otp!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            style={{ width: "100%" }}
          >
            <Button type="primary" htmlType="submit">
              Verify Otp
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
export default ForgotPassword;
