import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Select } from "antd";
import { _fetch } from "./_fetch";
import { useCookies } from "react-cookie";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { City, State } from "country-state-city";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase/setup";
const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};
const UserForm = () => {
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [cookies, setCookie] = useCookies(["gsa-user"]);
  const [userdetails, setUserDetails] = useState(undefined);
  const navigate = useNavigate();
  const [verifyPhone, setVerifyPhone] = useState(false);
  const [phone, setPhone] = useState();
  const [logInByOtp, setLoginByOtp] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [userverifybyotp, setUserVerifyByOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const sendOtpToCustomer = async (value) => {
    try {
      setLoading(true);
      let res = await _fetch(`${apiurl}/api/v1/user/filter`, {
        method: "POST",
        body: { loginByOtp: true, phone: phone },
      });
      res = await res.json();
      if (res.success) {
        return message.error(`User with ${phone} exists`);
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      if (!recaptcha) {
        return message.error("Too Many Attempts");
      }
      setLoading(false);
      const confirmation = await signInWithPhoneNumber(
        auth,
        "+" + phone,
        recaptcha
      );
      setUserVerifyByOtp(confirmation);
      setSendOtp(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err.message);
      setLoading(false);
    }
  };
  const verifyOtp = async (value) => {
    try {
      let x = await userverifybyotp.confirm(otp);
      createUser();
    } catch (err) {
      console.error(err);
    }
  };
  const createUser = async () => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/create`, {
        method: "POST",
        body: {
          ...userdetails,
          phone,
          "address.state": State.getStateByCodeAndCountry(
            userdetails.state,
            "IN"
          ).name,
        },
      });
      res = await res.json();
      console.log(res);
      if (res.success) {
        message.success("User Created");
        setCookie("crafti&%373loggin", res.response, { path: "/" });
        message.success("User LoggedIn");
        navigate("/");
      } else {
        message.error(res.response);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const onFinish = async (values) => {
    try {
      // setVerifyPhone(true);
      setUserDetails(values);
      console.log(values);
      createUser();
    } catch (err) {
      console.error(err);
      message.error("Something Went Wrong");
    }
  };
  return (
    <>
      {verifyPhone ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            minHeight: "79vh",
          }}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            autoComplete="off"
          >
            <Form.Item label="Phone" name="phone">
              <PhoneInput
                country={"in"}
                value={phone}
                onChange={(e) => setPhone(e)}
              />
            </Form.Item>
            {sendOtp ? (
              <Form.Item
                label="Otp"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input your otp!",
                  },
                ]}
                style={{ width: "200px" }}
              >
                <Input
                  onChange={(e) => {
                    setOtp(e?.target?.value);
                  }}
                />
              </Form.Item>
            ) : (
              ""
            )}
            {!sendOtp ? (
              <>
                <Form.Item>
                  <Button type="primary" onClick={() => sendOtpToCustomer()}>
                    Send Otp
                  </Button>
                  {/* <a
                    type="primary"
                    onClick={() => setLoginByOtp(false)}
                    style={{ marginLeft: "10px" }}
                  >
                    Login By Password
                  </a> */}
                  {/* <a
                    type="primary"
                    onClick={() => navigate("/user/add")}
                    style={{ marginLeft: "10px" }}
                  >
                    Sign Up
                  </a> */}
                </Form.Item>
              </>
            ) : (
              <Form.Item>
                <Button type="primary" onClick={() => verifyOtp()}>
                  Verify
                </Button>
              </Form.Item>
            )}
            <div id="recaptcha"></div>
          </Form>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            minHeight: "79vh",
          }}
        >
          <Form
            name="basic"
            style={{ width: "50%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name">
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please input valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="Country"
              name="address.country"
              rules={[
                {
                  required: true,
                  message: "Please input Country",
                },
              ]}
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.country !== currentValues.country
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "State is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {State.getStatesOfCountry("IN").map((el, i) => (
                        <option key={i} value={el.isoCode}>
                          {el.name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.state !== currentValues.state
              }
            >
              {({ getFieldValue }) => {
                const state = getFieldValue("state");
                return (
                  <Form.Item
                    name="address.city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "City is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a City"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {console.log(City.getCitiesOfState("IN", state), state)}
                      {City.getCitiesOfState("IN", state).map((el, i) => (
                        <option key={i} value={el.name}>
                          {el.name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              label="Address/Flat No:"
              name="address.street"
              rules={[
                {
                  required: true,
                  message: "Please input Street",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pincode"
              name="address.pincode"
              rules={[
                {
                  required: true,
                  message: "Please input pincode",
                },
                {
                  pattern: /^\d{6}$/,
                  message: "Please give correct pincode",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};
export default UserForm;
