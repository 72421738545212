import React, { useState, useEffect } from "react";
import { Button, Space, Table, Tag } from "antd";
import { _fetch } from "./_fetch";
import { SignInMethod } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

let apiurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT
    : process.env.REACT_APP_API;

export default function ProductList() {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              let res = await _fetch(`${apiurl}/api/v1/product/${record._id}`, {
                method: "DELETE",
              });
              res = await res.json();
              if (res.success) setProduct(res.response);
            }}
          >
            Delete
          </Button>
          <Button
            onClick={async () => {
              navigate(`/product/edit/${record._id}`);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  const [product, setProduct] = useState([]);
  const [cookie] = useCookies(["crafti&%373loggin"]);

  async function getData() {
    let res = await _fetch(`${apiurl}/api/v1/product`);
    res = await res.json();
    if (res.success) {
      setProduct(res.response);
    }
  }
  useEffect(() => {
    let userrole = cookie["crafti&%373loggin"]?.role;
    console.log(userrole !== "SUPERADMIN");
    if (userrole !== "SUPERADMIN") {
      navigate("/");
    }
    getData();
  }, []);
  return <Table columns={columns} dataSource={product} />;
}
