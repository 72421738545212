import React from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import image1 from "../assets/carouselimage/image1.jpg";
import image2 from "../assets/carouselimage/image2.jpg";
import image3 from "../assets/carouselimage/image3.jpg";
import image4 from "../assets/carouselimage/image4.jpg";
import image5 from "../assets/carouselimage/image5.jpg";
import image01 from "../assets/carouselimage/image01.jpg";
import image02 from "../assets/carouselimage/image02.jpg";
import image03 from "../assets/carouselimage/image03.jpg";
import image04 from "../assets/carouselimage/image04.jpg";
import coconut from "../assets/carouselimage/coconut.jpg";
import him from "../assets/him.webp";
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "15px",
        lineHeight: "1.5715",
      }}
      onClick={onClick}
    >
      <RightOutlined />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "15px",
        lineHeight: "1.5715",
      }}
      onClick={onClick}
    >
      <LeftOutlined />
    </div>
  );
};
// const settings = {
//   nextArrow: <SampleNextArrow />,
//   prevArrow: <SamplePrevArrow />,

// };
const settings = {
  autoplay: true,
  autoplaySpeed: 2000, // Adjust the speed as needed
  effect: "fade",
  speed: 2000,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
const Carouse = () => (
  <Carousel arrows {...settings}>
    {/* <h3 style={contentStyle}>1</h3> */}
    <img src={image01} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image02} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image03} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image04} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image1} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image2} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image3} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image4} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
    <img src={image5} style={{ objectFit: "contain" }}></img>
    <img src={coconut} style={{ objectFit: "contain" }}></img>
  </Carousel>
);
export default Carouse;
