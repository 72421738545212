import React, { useState, useEffect } from "react";
import { Button, message, Modal, Space, Table, Tag } from "antd";
import { _fetch } from "./_fetch";
import { SignInMethod } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import moment from "moment";
const { confirm } = Modal;
let apiurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT
    : process.env.REACT_APP_API;

export default function Order() {
  const columns = [
    {
      title: "Ordered At",
      render: (_, record) => {
        const formattedDate = moment(record?.createdAt).format(
          "MMMM Do YYYY, h:mm:ss a"
        );
        return <div>{formattedDate}</div>;
      },
    },
    {
      title: "Name",
      render: (_, record) => {
        return (
          <div
            onClick={() =>
              navigate(`/product/${record?.itemdata?.product?.slug}`)
            }
          >
            <a>{record?.itemdata?.product?.name}</a>
          </div>
        );
      },
    },
    {
      title: "Price Paid",
      render: (_, record) => {
        return (
          <div
            onClick={() =>
              navigate(`/product/${record?.itemdata?.amountpaidbycustomer}`)
            }
          >
            <a>{record?.itemdata?.amountpaidbycustomer}</a>
          </div>
        );
      },
    },
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              console.log(record);
              showDeleteConfirm(record);
            }}
          >
            Cancel
          </Button>
        </Space>
      ),
    },
  ];

  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [cookie] = useCookies(["crafti&%373loggin"]);
  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this item?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        let res = await _fetch(`${apiurl}/api/v1/order/cancelorder`, {
          method: "POST",
          body: {
            id: record?._id,
            product_id: record?.itemdata?.product?._id,
          },
        });
        res = await res.json();
        if (res.success) {
          let newproduct = product.filter((data) => {
            const itemDataMatch =
              data?.itemdata?.product?._id?.toString() ===
              record?.itemdata?.product?._id?.toString();
            const idMatch = data?._id?.toString() === record?._id?.toString();
            return !(itemDataMatch && idMatch);
          });
          console.log(newproduct);
          setProduct(newproduct);
          message.success("Your order has been cancelled");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  async function getData() {
    let userid = cookie["crafti&%373loggin"]?._id;
    let ordersofuser = await _fetch(
      `${apiurl}/api/v1/user/getorderofuser/${userid}`
    );
    ordersofuser = await ordersofuser.json();
    if (ordersofuser.success) {
      let finaldata = [];
      ordersofuser?.response?.map((data) => {
        data?.item?.map((itemdata) => {
          finaldata.push({ ...data, itemdata });
        });
      });
      setProduct(finaldata);
      console.log(finaldata);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {product?.length > 0 ? (
        <Table columns={columns} dataSource={product} />
      ) : (
        <h1>No Orders Found</h1>
      )}
    </div>
  );
}
