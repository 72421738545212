import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { _fetch } from "./_fetch";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import axios from "axios";
import cloudinary from "cloudinary-core";
import { uploadFile } from "./services/fileUpload";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};
const ProductForm = () => {
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewImagelogo, setPreviewImageLogo] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewTitlelogo, setPreviewTitleLogo] = useState("");
  const [fileList, setFileList] = useState([]);
  const [logo, setLogo] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [bannerImages, setBannerImages] = useState([]);
  const [inputTagCount, setTagInputCount] = useState(0);
  const [taginputs, setTagInputs] = useState([]);
  const [taginputValues, setTagInputValues] = useState({});
  const [aboutItemCount, setAboutItemCount] = useState(0);
  const [aboutIteminputs, setAboutItemInputs] = useState([]);
  const [aboutIteminputValues, setAboutItemInputValues] = useState({});
  const [descCount, setDescCount] = useState(0);
  const [descInput, setDescInputs] = useState([]);
  const [descValues, setDescValues] = useState({});

  const addDesc = () => {
    // Check if the last input box has a value
    if (descCount > 0 && !descValues[descCount - 1]) {
      message.error(`Please add Description ${descCount}`);
      return;
    }
    setDescCount((prevCount) => prevCount + 1);
    setDescInputs((prevInputs) => [...prevInputs, descCount]);
  };

  const handleDescChange = (e, index) => {
    const { value } = e.target;
    setDescValues((prevInputValues) => ({
      ...prevInputValues,
      [index]: value,
    }));
  };

  const addTag = () => {
    // Check if the last input box has a value
    if (inputTagCount > 0 && !taginputValues[inputTagCount - 1]) {
      message.error(`Please add Tag ${inputTagCount}`);
      return;
    }
    setTagInputCount((prevCount) => prevCount + 1);
    setTagInputs((prevInputs) => [...prevInputs, inputTagCount]);
  };
  const addAboutItem = () => {
    if (aboutItemCount > 0 && !aboutIteminputValues[aboutItemCount - 1]) {
      message.error(`Please add Tag ${aboutItemCount}`);
      return;
    }
    setAboutItemCount((prevCount) => prevCount + 1);
    setAboutItemInputs((prevInputs) => [...prevInputs, aboutItemCount]);
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setTagInputValues((prevInputValues) => ({
      ...prevInputValues,
      [index]: value,
    }));
  };
  const handleAboutItemChange = (e, index) => {
    const { value } = e.target;
    setAboutItemInputValues((prevInputValues) => ({
      ...prevInputValues,
      [index]: value,
    }));
  };
  const onFinish = async (values) => {
    try {
      let product = await _fetch(`${apiurl}/api/v1/product/create`, {
        method: "POST",
        body: {
          ...values,
          tags: Object.keys(taginputValues)
            .filter((data) => {
              return taginputValues[data] && taginputValues[data] !== "";
            })
            .map((data) => {
              return taginputValues[data];
            }),
          description: Object.keys(descValues)
            .filter((data) => {
              return descValues[data] && descValues[data] !== "";
            })
            .map((data) => {
              return descValues[data];
            }),
          about_item: Object.keys(aboutIteminputValues)
            .filter((data) => {
              return (
                aboutIteminputValues[data] && aboutIteminputValues[data] !== ""
              );
            })
            .map((data) => {
              return aboutIteminputValues[data];
            }),
        },
      });
      product = await product.json();
      message.error("Product");
      if (!product.success) {
        message.error("Product Not Added");
      }
      if (product.success) {
        if (logo.length) {
          await new Promise(async function (resolve, reject) {
            await uploadFile(
              logo[0].originFileObj,
              product.response?._id,
              product.response?._id,
              _fetch,
              false,
              resolve,
              reject
            );
          })
            .then(async (val) => {
              let updatedproduct = await _fetch(
                `${apiurl}/api/v1/product/${product?.response?._id}`,
                {
                  method: "PATCH",
                  body: { logo: val },
                }
              );
              updatedproduct = await updatedproduct.json();
            })
            .catch((e) => {
              console.error("error", e);
            });
        }
        fileList.map(async (data, ind) => {
          await new Promise(async function (resolve, reject) {
            await uploadFile(
              data.originFileObj,
              product.response?._id,
              product.response?._id,
              _fetch,
              false,
              resolve,
              reject
            );
          });
        });
        message.success(`${values.name} has been added to list`);
      }
    } catch (err) {
      console.error(err);
      message.error("Something Went Wrong");
    }
  };
  async function getData() {
    let Categories = await _fetch(`${apiurl}/api/v1/category`);
    Categories = await Categories.json();
    let SubCategories = await _fetch(`${apiurl}/api/v1/subcategory`);
    SubCategories = await SubCategories.json();
    setCategory(Categories.response);
    setSubCategory(SubCategories.response);
  }
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handlePreviewlogo = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageLogo(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitleLogo(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setBannerImages((prevdata) => {
      let arr = prevdata;
      arr.push(newFileList);
      return arr;
    });
    setFileList(newFileList);
  };
  const handleChangeLogo = ({ fileList: newFileList, file }) => {
    return setLogo(newFileList);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  useEffect(() => {
    if (firstLoad) {
      getData();
      setFirstLoad(false);
    }
    window.cloudinary = new cloudinary.Cloudinary({ cloud_name: "dhzouknuj" });
  }, [category]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        minHeight: "79vh",
      }}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        style={{ width: "50%" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Product Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Product Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            {
              required: true,
              message: "Please input your Slug!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div
          style={{
            margin: "5px 0",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <p>Description</p>
          {descInput?.map((index) => (
            <div key={index} style={{ marginTop: "10px" }}>
              <Input
                placeholder={`Tag ${index + 1}`}
                value={descValues[index] || ""}
                onChange={(e) => handleDescChange(e, index)}
              />
            </div>
          ))}
          <Button onClick={addDesc}>Add Description</Button>
        </div>
        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
              type: Number,
              message: "Please input your price!",
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Stock Available"
          name="stock"
          rules={[
            {
              required: true,
              message: "Please input your price!",
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label="Discount " name="discount">
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Category"
          name="category_id"
          rules={[
            {
              required: true,
              message: "Please input your Category!",
            },
          ]}
        >
          <Select
            style={{
              width: 120,
            }}
            options={category?.map((data) => {
              return {
                value: data._id,
                label: data.name,
              };
            })}
          />
        </Form.Item>

        <Form.Item
          label="Sub Category"
          name="sub_category_id"
          rules={[
            {
              required: true,
              message: "Please input your Sub Category!",
            },
          ]}
        >
          <Select
            style={{
              width: 120,
            }}
            options={subCategory?.map((data) => {
              return {
                value: data._id,
                label: data.name,
              };
            })}
          />
        </Form.Item>
        <div
          style={{
            margin: "5px 0",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <p>About Item</p>
          {aboutIteminputs?.map((index) => (
            <div key={index} style={{ marginTop: "10px" }}>
              <Input
                placeholder={`Tag ${index + 1}`}
                value={aboutIteminputValues[index] || ""}
                onChange={(e) => handleAboutItemChange(e, index)}
              />
            </div>
          ))}
          <Button onClick={addAboutItem}>Add Detail</Button>
        </div>
        <div
          style={{
            margin: "5px 0",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <p>Tags</p>
          {taginputs?.map((index) => (
            <div key={index} style={{ marginTop: "10px" }}>
              <Input
                placeholder={`Tag ${index + 1}`}
                value={taginputValues[index] || ""}
                onChange={(e) => handleInputChange(e, index)}
              />
            </div>
          ))}
          <Button onClick={addTag}>Add Tag</Button>
        </div>
        <Form.Item label="Logo" name="logo">
          <Upload
            listType="picture-circle"
            fileList={logo}
            onPreview={handlePreviewlogo}
            onChange={handleChangeLogo}
          >
            {logo ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitlelogo}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImagelogo}
            />
          </Modal>
        </Form.Item>
        <Form.Item label="Images" name="image">
          <Upload
            listType="picture-circle"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ProductForm;
